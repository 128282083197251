<template>
  <Card>
    <template #title>Usuários Consignatárias</template>
    <template #content>
      <DataTable
        class="p-datatable-sm"
        :value="usuarios"
        :paginator="true"
        :rows="15"
        :rowsPerPageOptions="[10, 15, 25]"
        :loading="loading"
        sortField="usuario.nome"
        :sortOrder="1"
        dataKey="id"
        :filters="filtros"
        :globalFilterFields="[
          'usuario.username',
          'consignataria.razaoSocial',
          'usuario.nome',
        ]"
        filterDisplay="menu"
        stripedRows
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} usuários consignatárias"
        responsiveLayout="scroll">
        <template #header>
          <div class="flex flex-column sm:flex-row">
            <span class="p-input-icon-left mb-2 mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="filtros['global'].value"
                placeholder="Pesquisar"
                style="width: 100%" />
            </span>
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Limpar"
              class="p-button-outlined mb-2"
              @click="limparFiltro" />
          </div>
        </template>

        <!-- <Column
          header="Razao Social"
          field="consignataria.razaoSocial"
          filterField="consignataria.razaoSocial"
          :showFilterMatchModes="false"
          :showFilterMenu="false">
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              :options="consignatarias"
              data-key="id"
              placeholder="Todos"
              class="p-column-filter"
              @change="filterCallback()">
              <template #option="slotProps">
                <div>
                  <span class="image-text">{{ slotProps.option }}</span>
                </div>
              </template>
            </Dropdown>
          </template>
        </Column> -->
        <Column field="usuario.username" header="Usuário" />
        <Column field="consignataria.razaoSocial" header="Razao Social" />
        <Column field="usuario.nome" header="Nome" />
        <Column field="usuarioMaster" header="Master" />
      </DataTable>
    </template>
  </Card>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import UsuarioService from '@/service/UsuarioService'

export default {
  data() {
    return {
      usuarios: null,
      loading: false,
      consignatarias: null,
      consignatariasSelecionadas: null,
      filtros: {},
    }
  },

  created() {
    this.service = new UsuarioService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.loading = !this.loading
    this.service.getListaUsuariosConsignatarias().then((res) => {
      this.usuarios = res
      this.loadConsignatarias()
      this.loading = !this.loading
    })
  },

  methods: {
    initFiltros() {
      this.filtros = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'usuario.username': {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      }
    },

    limparFiltro() {
      this.initFiltros()
    },

    loadConsignatarias() {
      let mySet = new Set()
      this.usuarios.map((obj) => {
        mySet.add(obj.consignataria.razaoSocial)
      })
      this.consignatarias = Array.from(mySet).sort()
    },

    formataCPF(cpf) {
      cpf = cpf.replace(/[^\d]/g, '')
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    },
  },
}
</script>

<style></style>
